<template>
  <div id="app">
    <Header />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/layout/Header'
import Footer from '@/components/layout/Footer'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&family=Oswald:wght@200;300;400;500;600;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}
.content-grid {
  padding-right: 15%;
  padding-left: 15%;
  @media(max-width: 1550px) {
    padding-right: 10%;
    padding-left: 10%;
  }
  @media(max-width: 1250px) {
    padding-right: 5%;
    padding-left: 5%;
  }
  @media(max-width: 1250px) {
    padding-right: 25px;
    padding-left: 25px;
  }
  @media(max-width: 365px) {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.mobile {
  display: none;
  @media(max-width: 768px) {
    display: block;
  }
}
a {
  text-decoration: none;
}
</style>
