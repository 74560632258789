<template>
    <div class="switch" @click="switchLang">
        [{{this.$i18n.locale == 'ua' ? 'en' : 'ua'}}]
    </div>
</template>

<script lang="ts">
export default {
    methods: {
        switchLang() {
            if(this.$i18n.locale == 'en') this.$i18n.locale = 'ua'
            else this.$i18n.locale = 'en'
            this.$emit('switch')
        }
    }
}
</script>

<style scoped lang="scss">
.switch {
    cursor: pointer;
}
</style>