import Vue from 'vue'
import Router from 'vue-router'
import { publicPath } from '../../vue.config'


Vue.use(Router)

export default new Router({
  scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  base: publicPath,
  
  routes: [
    {
      name: 'homepage',
      path: '/',
      component: () => import('@/views/main')
    },
    {
      name: 'project',
      path: '/project',
      component: () => import('@/views/project')
    },
  ]
})