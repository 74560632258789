<template>
    <header class="header content-grid">
        <Logo class="header__logo"/>
        <div class="header__right">
            <Navigation  class="header__nav header__nav_desktop"/>
            <Social type="header" class="header__icons" />
        </div>
        <Navigation  class="header__nav header__nav_mobile"/>
    </header>
</template>

<script>
import Navigation from './header/Navigation'
import Logo from './header/Logo'
import Social from '@/components/elements/Social'


export default {
    components: {
        Navigation,
        Logo,
        Social,
    }
}
</script>


<style lang="scss" scoped>
    .header {
        background: #000000;
        height: 156px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        &__nav_mobile {
            display: none;
        }
    }
    @media(max-width: 768px) {
        .header {
            padding-top: 30px;
            padding-bottom: 25px;
            height: 134px;
            flex-wrap: wrap;
            &__right {
                width: 50%;
            }
            &__nav_desktop {
                display: none;
            }
            &__nav_mobile {
                display: flex;
                margin-top: 20px;
            }
        }   
         
    }
   
</style>