<template>
    <div class="icons">
        <a :href="item.link" target="_blank" class="icons__item" v-for="item in items" :key="item.alt">
            <img :src="item.icon" :alt="item.alt">
        </a>
    </div>
</template>

<script>
export default {
    props: ['type'],
    data() {
        return {
            items: [
                { icon: this.$router.options.base + `media/${this.type}/telegram-icon.svg`, alt: 'tg', link: 'https://t.me/miskyitheatre_bot'},
                { icon: this.$router.options.base + `media/${this.type}/insta-icon.svg`, alt: 'inst', link: 'https://www.instagram.com/miskyi_theatre/' },
                { icon: this.$router.options.base + `media/${this.type}/fb-icon.svg`, alt: 'fb', link: 'https://facebook.com/miskyitheatre/' }
            ]
        }
    }
}
</script>

<style scoped lang="scss">
.icons {
    display: flex;
    align-items: center;
    
    &__item {
        cursor: pointer;
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media(max-width: 1050px) {
            img {
                height: 35px;
            }
        }
        @media(max-width: 768px) {
            margin-right: 10px;
            img {
                height: 25px;
            }
        }
        &:last-of-type {
            margin-right: 0;
        }
    }
}
</style>