<template>
    <ul class="nav" v-if="items">
        <template v-for="item in items">
            <li class="nav__item"  :key="`${item.text}${$i18n.locale}`" v-if="item.active">
                <router-link :to="item.link" v-if="!item.link.includes('#')">{{ $t(item.text) }}</router-link>
                <span v-else @click="scrollTo(item)">{{ $t(item.text) }}</span>
            </li>
        </template>
        <SwitchLang class="nav__item"/>

    </ul>
</template>

<script>
import SwitchLang from '@/components/elements/SwitchLang'

export default {
    props: ['type'],
    mounted() {
        setTimeout(() => {
            this.items = [
                {text: 'header.home', link: '/', active: this.type == 'mobile' && this.$route.name == 'homepage' ? false : true },
                {text: 'header.projects', link: '/project', active: this.type == 'mobile' && this.$route.name == 'project' ? false : true },
                {text: 'header.about_us', link: '#about_us', page: 'homepage', active: true},
            ]
        }, 1000)
    },
    data() {
        return {
            items: null
        }
    },
    components: {
        SwitchLang
    },
    methods: {
        async scrollTo(item) {
            const element = document.querySelector(item.link)
            if(element) this.scroll(element)
            else {
                await this.$router.push({name:item.page})
                setTimeout(() => {
                    this.scroll(document.querySelector(item.link))
                }, 1000)
            }
        },
        scroll(element) {
            element.scrollIntoView({block: "center", behavior: "smooth"})
        }
    }
}
</script>

<style scoped lang="scss">
.nav {
    display: flex;
    align-items: center;
    @media(max-width: 768px) {
        justify-content: space-between;
        width: 100%;
    }
    &__item {
        margin-right: 62px;
        font-size: 17px;
        line-height: 1.41;
        color: #999;
        list-style: none;
        @media(max-width: 1150px) {
            margin-right: 22px
        }
        a, span {
            color: inherit;
            cursor: pointer;
        }
        @media(max-width: 768px) {
            font-size: 10px;
            height: 24px;
            margin-right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
</style>
